@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap);

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

body {

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #CFCFCF;
  background-color: #2A3132;
}
p {
  margin-bottom: 1rem;
}
a{
  color: #FFFFFF;
}

details{
  margin-bottom: 1rem;
}
#root {
  min-height: 100vh;

  display: grid;
  grid-template-areas: "topTree" "one" "cone" "foot";
}

.parallax {
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center;
}

#topTree {
  height: 100vh;
  grid-area: topTree;
  background-image: url(/static/media/top.ffdb1a17.png);
  background-size: auto 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2vw;
  font-size: 3.5vw;
  color:#CFCFCF;
  text-shadow: -2px 2px 0 #000,
               2px 2px 0 #000,
               2px -2px 0 #000,
              -2px -2px 0 #000;
}

#one {
  grid-area: "one";
  width: 90%;
  margin: 16px auto;
}

#one ul {
  margin: 20px;
}
#cone {
  height: 575px;
  grid-area: cone;
  background-image: url(/static/media/coneBackgroundMobile.03381365.png);
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  text-transform: uppercase;
}

#foot {
  grid-area: foot;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
}

#footlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (min-width: 576px) {
  #topTree {
    background-size: cover;
  }
  #one {
    width: unset;
    padding:20px
  }

  #cone {
    background-size: cover;
    background-image: url(/static/media/coneBackground.0c991d9b.png);
  }
  #foot{
    width: 100%;
    padding: 20px;
  }
  
  #footlogo {
    width: unset;
  }
  .parallax {
    background-attachment: fixed;
  }
}


